#omnibox {
  --omni-width: 350px;
  --shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  --omni-ease: cubic-bezier(0.45, -0.19, 0.43, 1.16);
  --accent-color: var(--color-accent);
  --ac-bg: var(--color-bg);
  --ac-color: var(--color-fg);
  --ac-accent: var(--color-accent);
  --ac-accent-color-default: var(--color-accent);
  --ac-color-description: var(--color-fg);

  font-size: 16px;
  margin-top: var(--omnibox-margin-top);
  transition: margin-top 0.2s ease-in-out;
  position: absolute;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: right;
  top: 5px;
  right: 5px;

  z-index: 1;
  border-radius: 0.5rem;

  &:hover {
    box-shadow: var(--shadow);
  }

  span[data-label] {
    display: none;
  }

  > svg-icon {
    padding: 10px;
  }

  > input {
    margin-top: 10px;
    overflow: hidden;
    width: 0px;
    font-size: large;
    padding: 0;
    border: none;
    outline: none;
    transition: width 0.2s var(--omni-ease);

    &:focus {
      box-shadow: var(--shadow);
      border-radius: 0.5rem;
      padding: 0.3rem 0.6rem;
      overflow: visible;
      width: var(--omni-width);
    }
  }

  .ac-suggestion {
    width: var(--omni-width);
    overflow-y: scroll;

    .ac-empty {
      display: none !important;
    }

    .ac-item {
      &:hover,
      &.selected {
        color: black;

        .text {
          color: black;
          > small {
            color: black;
          }
        }
      }
    }

    &.ac-active {
      padding: 0.5rem;

      background-color: var(--color-bg);

      .ac-itm {
        padding: 0.4rem 0.8rem;
        height: 6rem;

        &:hover svg-icon,
        &.selected svg-icon {
          --icon-fill-color: black;
        }
      }
    }
  }
}

/* desktop view */
@media only screen and (min-width: 601px) {
  #omnibox.ac-container {
    .ac-suggestion {
      &.ac-active {
        position: absolute;
        margin-top: 50px;
        box-shadow: var(--shadow);
        width: var(--omni-width) !important;
        max-height: 500px;
      }
    }

    > input {
      &:focus {
        transition: width 0.2s var(--omni-ease);
      }
    }
  }
}

/* mobile view */
@media only screen and (max-width: 600px) {
  html[data-omnibox-shown] {
    overflow: hidden;
  }

  #omnibox.ac-container {
    box-shadow: none;
    //transition: none;

    .ac-suggestion {
      position: fixed;
      left: 100vw;
      transition: left 0.2s ease; //, width 0.5s ease;
      opacity: 0;
      .ac-itm {
        display: none;
      }

      &.ac-active {
        //transition: none;
        transition: left 0.2s ease; //, width 0.5s ease;
        opacity: 1;
        margin: 0;
        top: var(--header-h);
        left: 0;
        width: 100vw;
        height: calc(100vh - var(--header-h));
        max-height: unset;
        max-width: unset;

        .ac-itm {
          display: grid;
        }
      }
    }

    > input {
      background-color: #222;
      color: white;
      position: fixed;
      &:focus {
        top: 0;
        left: 0;
        height: var(--header-h);
        box-shadow: none;
        width: calc(100vw - 60px);
        padding: 0 0.4rem 1rem 1rem; /* top | right | bottom | left */
      }
    }
  }
}

html[data-scroll="down"] {
  #omnibox {
    > svg-icon {
      padding-top: 5px;
      transition: padding-top 0.3s var(--omni-ease);
    }
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    red 0,
    rgba(255, 0, 0, 0.2) 50%,
    transparent 100%
  );
  cursor: pointer;
}
