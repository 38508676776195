a[href].featured {
  position: relative;
  display: block;
  margin-top: 3rem;
  margin-bottom: 3rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);

  &:hover {
    text-decoration: none;
  }

  main {
    position: absolute;
    border-radius: 0 0 2rem 2rem;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline-block;
    width: 100%;
    text-shadow: 0 0 15px rgba(0, 0, 0, 1);
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0% 0%,
      rgb(0, 0, 0)
    );

    font-size: 1rem;
    display: flex;
    align-items: end;
    padding: 1rem;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    vertical-align: bottom;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:hover {
    box-shadow: 0 0 12px rgba(255, 255, 255, 0.2);
    text-decoration: none;
  }

  > article {
    text-decoration: none;

    header {
      display: flex;
      justify-content: space-between;
    }
  }
}
