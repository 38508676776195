html {
  &[data-persona="dev"] {
    [data-persona-related] {
      [data-persona="dec"] {
        opacity: 0.4;
      }

      &[data-persona-related="toggle"] {
        [data-persona="dec"] {
          display: none;
        }
      }
    }
    [data-personas] {
      [data-persona="dev"] {
        border-color: var(--color-h);
      }
    }
  }

  &[data-persona="dec"] {
    [data-persona-related] {
      [data-persona="dev"] {
        opacity: 0.4;
      }

      &[data-persona-related="toggle"] {
        [data-persona="dev"] {
          display: none;
        }
      }
    }

    [data-personas] {
      [data-persona="dec"] {
        border-color: var(--color-h);
      }
    }
  }

  
  &[data-transition="forwards"]{
    [data-backwards-only]{
      display: none;
    }
  }

  &[data-transition="backwards"]{
    [data-forwards-only]{
      display: none;
    }
  }

}

