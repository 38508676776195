@import "reset";
@import "variables";
@import "scrollbar";
@import "../../node_modules/pure-web/src/scss/autocomplete";
@import "functional";
@import "./fans";
@import "./tiles";
@import "./ac";
@import "featured";

html {
  color: var(--color-fg);
  background-color: var(--color-bg);
}

body {
  position: relative;
  margin: var(--body-margin-v) var(--body-margin-h);
  max-width: var(--max-width);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  background: #282537;
  background-image: -webkit-radial-gradient(
    20%,
    circle cover,
    var(--color-bg) 0%,
    var(--color-bg-nuance) 80%
  );
  overflow-x: hidden;
  overflow-y: scroll;

  pure-app {
    display: block;
    border-radius: var(--app-rad);
    height: auto;
    grid-template-rows: 100px 1fr 50px;

    > header {
      position: fixed;
      right: 0;
      top: 0;
      left: 0;
      padding: 0;
      z-index: 1;
    }

    > main {
      /* Top | Right | Bottom | Left */
      padding-left: var(--padding-normal);
      padding-right: var(--padding-normal);
      margin-top: var(--header-h);
    }

    > footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #222;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      justify-items: center;
      justify-content: center;
      padding: 1rem 2rem;
      font-size: small;
      border-radius: 3rem 3rem 0 0;
      transition: bottom 0.2s ease-in-out;
    }

    p {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .sumup {
      list-style: decimal;
      padding: 0;
      margin-left: 1rem;

      li {
        margin-bottom: 1rem;
        font-weight: 800;

        small {
          font-weight: 400;
          display: block;
        }

        > p {
          margin-top: 0 !important;
        }
      }

      &.large {
        font-size: 2vh;

        li {
          margin-bottom: 2vh;
        }
      }
    }
  }

  h2,
  h3,
  h4 {
    font-variant: small-caps;
    margin-bottom: 1rem;
  }

  h2-sub,
  h3-sub {
    margin-top: -1rem;
    display: block;
    font-weight: 200;
    font-size: smaller;
  }

  h2 {
    letter-spacing: 0.1rem;
    font-weight: 200;
    margin-top: 3rem;
  }

  blockquote {
    --color-1: rgba(0, 0, 0, 0.03);
    --color-2: rgba(0, 0, 0, 0.04);
    --color-3: rgba(0, 0, 0, 0.05);
    --color-4: rgba(0, 0, 0, 0.06);

    text-align: center;

    border-radius: 2rem;
    font-size: xx-large;
    padding: var(--padding-large);
    margin: var(--padding-normal);

    background: radial-gradient(var(--color-1) 50%, transparent 90%),
      radial-gradient(var(--color-2) 65%, transparent 90%),
      radial-gradient(var(--color-3) 25%, transparent 90%),
      radial-gradient(var(--color-4) 25%, transparent 90%);
    background-size: 80% 90%, 85% 90%, 90% 95%, 95% 99%;
    background-position: 0 0, 0 100%, 50% 0, 50% 100%;
    background-repeat: no-repeat;

    &.hero {
      position: relative;
      font-size: 4vh;
      text-transform: uppercase;
      line-height: 4.1vh;

      &::first-letter {
        font-size: 300%;
        z-index: 0;
        color: var(--color-h);
        font-weight: 100;
        font-family: Georgia, "Times New Roman", Times, serif;
      }
    }
  }

  section {
    padding: 1rem;

    h1,
    h2 {
      &:first-child {
        margin-top: 0rem;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a[href] {
    color: var(--color-h);
  }

  a[href] {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }

    &.fetching {
      animation: fetching 1s infinite;
    }
  }

  .site-header {
    height: var(--header-h);
    transition: height 0.2s ease-in-out;
    margin: 0 var(--body-margin-h);

    max-width: var(--max-width);
    display: block;
    background-color: #222;
    background-image: url("/assets/img/pure-logo.svg");
    background-repeat: no-repeat;
    background-size: contain;

    background-position: var(--header-bg-pos);

    h1 {
      opacity: 0;
    }
  }

  > main {
    margin-top: var(--header-h);
    min-height: 100lvh;
  }

  dfn {
    border-bottom: 2px dotted var(--color-h);
    cursor: help;
    position: relative;
  }
}

@media only screen and (max-width: 600px) {
  body {
    dfn.active {
      &::before {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
      }

      &::after {
        position: fixed;
        left: calc(50% + 124px);
        top: unset;
        transform: translateX(-50%);
      }
    }

    blockquote {
      font-size: x-large;
    }
  }
}

html {
  &[data-scroll="down"] {
    footer {
      bottom: -200px;
      transition: bottom 0.2s ease-in-out;
    }

    > header {
      .site-header {
        transition: height 0.2s ease-in-out;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
      }
    }
  }
}

page-manifesto {
  position: relative;
}

#manifesto-steps {
  position: absolute;
  margin: 0;
  top: 0px;
  right: 0px;
  display: flex;
  gap: 0.4rem;
  flex-direction: row;
  a {
    width: 32px;
    height: 32px;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    border-radius: 100%;
    background-color: var(--color-h);
    color: black;
    font-size: 24px;

    &:hover {
      text-decoration: none;
      color: var(--color-h);
      background-color: black;
    }

    &[hidden] {
      display: inline-block;
      opacity: 0.3;
      pointer-events: none;
    }

    &.manifesto-home {
      filter: saturate(40%);
      font-size: 20px;
    }
  }
}

@keyframes slidein {
  from {
    transform: translateX(-10rem);
    opacity: 0;
  }

  to {
    transform: translate(0);
    opacity: 1;
  }
}

.badges {
  margin-top: 2rem;
  margin-bottom: 2rem;
  list-style: none;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;

  > * {
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    color: inherit;
    font-size: smaller;

    a[href] {
      color: inherit;
      font-weight: 200;
    }
  }
}

.accordion {
  margin-top: 2rem;

  > li {
    list-style: none !important;

    > details[open] {
      margin-bottom: 2rem;

      > summary {
        font-weight: 600;
      }
    }
  }

  .badges {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    > li {
      list-style: none;
    }
  }
}

#semantic-elements {
  > li {
    > details[open] {
      > summary {
        margin-top: 0.4rem;
        margin-left: 1.5rem;
        margin-bottom: 1.2rem;
      }
    }
  }
}

details {
  border: 1px solid #222;

  border-radius: 1rem;
  padding: 0.5rem;
  margin-bottom: 1rem;

  > ::marker {
    content: "";
    display: none;
  }

  > ul {
    max-height: 3rem;
    transition: max-height 0.3s ease-out;

    li {
      list-style: circle;
      margin-left: 1.5rem;
    }
  }

  &[open] {
    border-color: var(--color-h);
    max-height: 100vh;

    animation: slideDown 0.3s forwards;

    > ul {
      max-height: 100vh;

      transition: max-height 0.3s ease-out;
    }
  }

  &:not([open]) {
    animation: slideUp 0.3s forwards;
  }

  summary {
    user-select: none;
    font-size: large;
    cursor: pointer;

    &:before {
      content: none;
    }
  }
}

button {
  user-select: none;
  padding: 0.7rem 1.4rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  background-color: var(--color-accent);
  color: black;
  outline: none;
  border: 0;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.06);
}

@keyframes slideDown {
  0% {
    transform: translateY(-10px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-10px);
    opacity: 1;
  }
}

[data-tag] {
  &.active {
    text-decoration: underline;
  }
}

@keyframes fetching {
  20% {
    filter: brightness(105%);
  }
  30% {
    filter: brightness(110%);
  }
  40% {
    filter: brightness(115%);
  }
  50% {
    filter: brightness(120%);
  }
  60% {
    filter: brightness(125%);
  }
  70% {
    filter: brightness(120%);
  }
  80% {
    filter: brightness(110%);
  }
  90% {
    filter: brightness(105%);
  }
  100% {
    filter: none;
  }
}

slide-panel {
  h3 {
    border-radius: 1rem 1rem 0 0;
    margin: 0;
    text-align: center;
    margin-bottom: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.4rem 0.8rem;
    width: calc(100% - 50px);
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &[open] {
    h3 {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  }

  article {
    padding: 1rem;
  }

  ul {
    margin-top: 3rem;
  }

  h4 {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &.selected {
    background-color: #333;
  }

  article {
    background-color: var(--bg-color);
  }

  header {
    margin-top: 2rem;

    img {
      max-height: 120px;
      object-fit: contain;
      object-position: center;
      align-self: center;
      margin: auto;
      width: 100%;
    }
  }

  footer {
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }

  .badges {
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: smaller;
  }
}

.flex {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.error-page {
  width: 60%;
  border: 1px dashed var(--color-h);
  border-radius: 2rem;
  padding: 2rem;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
  margin: auto;

  h2 {
    margin-left: -90px;
  }
}

.page-content {
  margin-top: 2rem;

  img,
  video {
    border-radius: 1rem;
  }

  video,
  embed-yt {
    margin-top: 2rem;
  }
}

#page-icon {
  --icon-size: 50vmax;
  --icon-fill-color: rgba(255, 255, 255, 0.05);
  z-index: 0;
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
}

#breadcrumbs {
  font-size: small;
}

html[data-transition="forwards"] {
  .sumup {
    &.fly-in {
      --d: 0;

      li {
        animation: slidein 0.3s ease 1 both;
        animation-delay: var(--d);
      }

      li:nth-child(1) {
        --d: 0.4s;
      }
      li:nth-child(2) {
        --d: 0.8s;
      }
      li:nth-child(3) {
        --d: 1.2s;
      }
      li:nth-child(4) {
        --d: 1.6s;
      }
      li:nth-child(5) {
        --d: 2s;
      }
      li:nth-child(6) {
        --d: 2.4s;
      }
      li:nth-child(7) {
        --d: 2.8s;
      }
      li:nth-child(8) {
        --d: 3.2s;
      }
      li:nth-child(9) {
        --d: 3.6s;
      }
      li:nth-child(10) {
        --d: 4s;
      }
      li:nth-child(11) {
        --d: 4.4s;
      }
      li:nth-child(12) {
        --d: 4.8s;
      }
      li:nth-child(13) {
        --d: 5.2s;
      }
      li:nth-child(14) {
        --d: 5.6s;
      }
      li:nth-child(15) {
        --d: 6s;
      }
    }
  }
}

input {
  border-radius: 0.5rem;
  font-size: large;
}

label:has(input[type="text"]),
label:has(input[type="email"]),
label:has(input[type="number"]),
label:has(input[type="password"]),
label:has(input[type="url"]),
label:has(input[type="tel"]),
label:has(input[type="search"]),
label:has(input[type="date"]),
label:has(select) {
  position: relative;
  display: block;

  > span[data-label] {
    display: inline-block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    padding: 0 5px;
    transform: translateY(-50%);
    left: var(--gutter-small);
    will-change: top, left;
    transition: all 0.2s ease;
    text-wrap: nowrap;
    z-index: 1;
    border-radius: 0.4rem;
    color: black;
  }

  &:has([required]) > span::after {
    content: "*";
    position: absolute;
    color: var(--color-accent, red);
    top: -20%;
  }

  input,
  select {
    width: 100%;
    padding: 0.75rem 1.6rem;
    margin: 0;
    line-height: 26px;
    outline: none;
  }

  &:has(span[data-label]) {
    input,
    select {
      &::placeholder {
        opacity: 0;
      }
    }
  }

  &:has(input:focus),
  &:has(input:not(:focus):not(:placeholder-shown)),
  &:has(input:not(:placeholder-shown)) &:has(select:focus),
  &:has(select:not(:placeholder-shown)) {
    span[data-label] {
      background-color: var(--color-bg-nuance);
      font-size: smaller;
      top: 0;
      will-change: top, left;
      transition: all 0.2s ease;
      text-wrap: nowrap;
      z-index: 1;
      color: white;
    }
  }

  &:has(input:focus),
  &:has(select:focus) {
    input,
    select {
      border-color: var(--color-primary-000);

      &::placeholder {
        opacity: 1;
      }
    }
  }
}

form {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  img {
    border-radius: var(--border-r);
    display: inline-block;
    margin: auto;
  }
  figcaption {
    font-size: x-small;
    font-style: italic;
    text-align: center;
    padding: 0.3rem;
  }
}

#footnotes {
  margin-top: 1rem;
  font-size: smaller;
  border-top: 2px solid #777;
}

[data-personas] {
  display: block;
  h2 {
    text-align: center;
  }
  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    justify-content: space-around;

    > * {
      width: 40%;
      max-width: 200px;
    }

    [href="/do-no-filter"] {
      filter: saturate(40%);
    }
  }
}

li[data-tag] {
  border: 2px dotted var(--color-h);
  cursor: help;
}

table {
  &.stats {
    min-width: 60%;
    max-width: 640px;
    background-color: #333;
    border-collapse: collapse;
    border-radius: var(--border-r);

    thead {
      border-radius: var(--border-r);
    }

    th {
      text-align: left;
      background-color: #222;
      padding: 0.6rem 0.6rem;
    }

    caption,
    td {
      padding: 0.3rem 0.6rem;
    }

    caption {
      display: none;
    }

    th {
      border-radius: var(--border-r) var(--border-r) 0 0;
    }

    th,
    td {
      border: 1px solid #444;
    }

    tbody {
      font-size: smaller;
    }
  }
}

.blog-list {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  article {
    display: grid;
    grid-gap: 1rem;
    //padding-right: 2rem;
    grid-template-areas:
      "img title title"
      "img description description"
      "img author date";

    grid-template-columns: 250px 1fr;
    grid-template-rows: 1.4rem 1fr 2rem;

    figure {
      grid-area: img;
    }

    a {
      grid-area: title;
    }

    time {
      text-align: right;
    }

    small {
      grid-area: description;
      font-size: smaller;
      column-span: 2;
    }
  }
}

article {
  img {
    object-fit: cover;
    max-height: 500px;
    border-radius: 1rem;
  }

  &.single {
    .pub-info {
      margin-bottom: 2rem;
    }

    img {
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media only screen and (max-width: 600px) {
  slide-panel[open] {
    h3 {
      margin-top: var(--header-h);
    }
  }

  figure {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  table {
    &.stats {
      width: 100%;
    }
  }

  .blog-list {
    article {
      display: flex;
      gap: 0.3rem;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      justify-content: center;
      grid-template-areas: unset;
      grid-template-rows: unset;
      grid-template-columns: unset;

      img {
        margin: auto;
      }
      .pub-info {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
    }
  }
}

.sub-header-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-bottom: 1rem;

  > :first-child {
    margin-bottom: 0 !important;
  }

  > small {
    font-size: small;
  }
}

del {
  color: #ec4654;
}

ins {
  color: #4bb154;
}

.simple-list {
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  li {
    margin-top: 1rem;
  }
  small {
    display: block;
  }
}

ul.simple-list {
  list-style: outside;
}

ol.simple-list {
  list-style: decimal;
}

.showcased {
  background-color: #222;
  color: var(--color-h);
  transition: all 0.2s ease;
}

.pub-info {
  grid-area: author;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: small;
}

.ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#info-sources {
  a {
    background-color: white;
  }
}

.mention-results {
  position: absolute;
  margin-top: 0.4rem;
  display: none;
  flex-direction: row;
  gap: 0.4rem;
  font-size: 14px;

  &.open {
    display: flex;
  }

  a:link,
  a:visited {
    color: #333;
    text-decoration: none;
    display: inline-block;
    background-color: #ccc;
    border-radius: 0.4rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

.split {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: row;
  gap: 2rem;
}

.v-space {
  margin: 0;
  padding: 0;
  margin-top: 2rem;
}

a[href]:has(svg-icon) {
  display: inline-flex;
  gap: 0.2rem;
}

.profile {
  h3 {
    font-size: 3rem;
  }
}

#tag-cloud {
  margin-top: 2rem;
  margin-bottom: 1rem;
  background-color: #222;
  border-radius: 1rem;
  padding: 1rem;
}

call-out,
coming-soon {
  margin-left: 50%;
  min-width: 600px;
  transform: translateX(-50%);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

code {
  font-family: consolas, "Courier New", Courier, monospace;
  color: var(--color-h);
}

@media only screen and (max-width: 600px) {
  .split {
    flex-direction: column;
  }

  call-out,
  coming-soon,
  code-example {
    min-width: calc(100vw - 4rem);
    width: unset;
    margin-left: unset;
    transform: unset;
  }
}

.interaction {
  cursor: pointer;
}

.converted-md {
  ul {
    list-style: unset;
    margin: inherit;
    padding: inherit;

    li {
      padding: inherit;
      margin: 1.15rem;
    }
  }
}

page-blog {
}

.bg-img {
  background-size: cover;
  background-image: var(--img);
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 1s ease-in-out;
  min-height: 400px;
  max-width: 700px;
  padding: 1rem;
  margin: auto;
  border-radius: 2rem;

  h2 {
    margin: 0;
    color: white;
    font-size: clamp(1rem, 3vh + 1rem, 5rem);
    font-variant: normal;
    text-transform: uppercase;
    text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.8);
    font-weight: 800;
    line-height: clamp(1rem, 3vh + 1rem, 5rem);
  }
}

html:not([data-transition="backwards"]) {
  .wait-for-images {
    --delay-step: 0.01s;
    profile-image,
    fan-image {
      transform: translateX(20vw);
      opacity: 0;;
    }

    &.images-loaded {
      profile-image,
      fan-image {
        transform: translateX(0vw);
        opacity: 1;
        transition: all 0.1s ease-out;
      }
    }
  }
}
