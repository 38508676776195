.tiles {
  --title-height: 3rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  padding-bottom: 2rem;

  &.list {
    grid-template-columns: 1fr;
  }

  a[href] {
    border-radius: 1rem;
    border: 1px solid #111;
    height: calc(120px + var(--title-height));
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-items: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    --icon-size: 64px;

    span {
      font-size: small;
    }

    &.img-tile {
      background-image: var(--img);
      background-size: cover;
      background-position: 0;
      background-repeat: no-repeat;
      justify-content: flex-end;
      position: relative;

      span {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: inline-block;
        width: 100%;
        height: 4rem;
        text-align: center;
        overflow-y: hidden;
        font-size: small;
        text-shadow: 1px 1px 4px rgba(0,0,0,.6);
        padding: 2.6rem .5rem 0 .5rem;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0% 0%,  rgb(0, 0, 0));
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &[data-source="glossary"]{
      
    }
  }

  &.list {
    a[href] {
      display: grid;
      background-size: 150px;
      grid-template-columns: 150px 1fr;
      height: 150px;
      justify-items: top;
      align-items: left;
      --icon-size: 64px;
      grid-template-areas:
        "none title"
        "none title"
        "none description";

      > span {
        position: unset;
        grid-area: title;
        background: transparent;
        text-align: left;
        font-size: 1.1rem;
      }
      > div {
        width: 100%;
        padding: 1rem;
        grid-area: description;
        text-align: left;
        text-decoration: none !important;
        font-size: small;
      }
    }
  }

  > .active {
    a[href] {
      border-color: var(--color-h);
    }
  }
}



