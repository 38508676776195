.profile-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: .5rem;
  padding-top: 10px;

  > nav {
    gap: 0.2rem;
  }

  span {
    button {
      display: none;
    }

    .profile-link {
      display: none;
    }

    .fan-type {
      display: none;
    }

    &.contributor a {
      border-color: lightgreen;
    }
    &.partner a {
      border-color: coral;
    }
    &.fan a {
      border-color: rgb(84, 178, 210);
    }
    &.owner a {
      border-color: var(--color-accent);
    }

    .none a {
      border-color: black;
    }
  }

  a {
    display: inline-block;
    border: 2px solid white;
    border-radius: 50%;
    background-color: white;
    background-image: var(--profile-img);
    background-size: cover;
    background-repeat: no-repeat;

    width: 200px;
    height: 200px;
  }

  &.small {
    fan-image {
      margin-right: 5px;
    }
  }

  &.normal {
    > nav {
      flex-wrap: wrap;
      max-width: 90%;
      gap: 2rem;
      justify-content: baseline;

      > span {
        position: relative;

        > article {
          display: none;
        }

        &.showcased {
          a {
            max-width: calc(var(--max-width) - 200px);
            width: 900px;
            height: 500px;
            border-radius: 1rem;
            transition: all 0.3s ease-in-out;

            background-size: contain;
            background-position: left;

            address {
              display: flex;
              flex-direction: row;
              justify-content: space-around;

              button {
                background-color: transparent;
                border: 0;
                outline: 0;
              }

              svg-icon {
                display: inline;
                --icon-fill-color: #fff;
                --icon-size: 24px;
              }
            }
          }

          article {
            position: absolute;
            top: 5px;
            right: 5px;
            color: #333;
            width: 200px;
            display: block;
          }
        }
      }
    }

    a.profile-link {
      display: none;
    }

    a.fan {
      width: 200px;
      height: 200px;
      display: flex;
      border-width: 3px;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: center;

      > address {
        background-color: rgba(0, 0, 0, 0.6);
        padding: 0.4rem 0.8rem;
        border-radius: 1rem;
        width: 10rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        font-size: smaller;
        color: white;
        box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.6);
      }

      svg-icon {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .profile-list {
    &.normal {
      nav {
        justify-content: center;
      }
      a {
        width: 300px;
        height: 300px;
      }
    }
  }
}

slide-panel {
  span.profile[id] {
    .fan {
      pointer-events: none;
    }

    .fan-type {
      grid-area: type;
    }

    .profile-link {
      position: absolute;
      top: 10px;
      right: 10px;
      margin-right: 50px;
    }

    article {
      padding-left: 0;
      padding-top: 0;

      span {
        margin-top: 0;
        font-style: italic;
        font-size: smaller;
        display: block;
        padding-bottom: 1rem;
      }
      div {
        grid-area: body;
        column-span: 2;
        font-size: smaller;
      }
    }
  }
}

[itemtype="http://schema.org/BlogPosting"]{
  .author img {
    height: 100px;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }
}